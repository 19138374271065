import { Alert as MantineAlert, type AlertProps as MantineAlertProps } from '@mantine/core'
import { IconCircleCheck, IconInfoCircle, IconInfoTriangle, IconCircleX } from '@tabler/icons-react'
import clsx from 'clsx'

import styles from './Alert.module.scss'

export interface AlertProps extends MantineAlertProps {
  variant: 'success' | 'info' | 'warning' | 'error'
}

export const Alert = ({ variant, children, ...rest }: AlertProps) => {
  const icons = {
    success: <IconCircleCheck />,
    info: <IconInfoCircle />,
    warning: <IconInfoTriangle />,
    error: <IconCircleX />,
  }

  return (
    <MantineAlert
      variant="light"
      color={variant}
      data-color={variant}
      className={clsx(!!rest.title && styles.hasTitle)}
      classNames={{ root: styles.root }}
      icon={icons[variant]}
      radius="sm"
      {...rest}
    >
      {children}
    </MantineAlert>
  )
}

import { IconLoader2 } from '@tabler/icons-react'
import clsx from 'clsx'

import styles from './IconLoading.module.scss'

import type { IconProps } from '@tabler/icons-react'

export const IconLoading = (props: IconProps) => (
  <IconLoader2 {...props} className={clsx(props?.className, styles.spinning)} />
)

import { TextInput } from '@mantine/core'
import axios from 'axios'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from 'src/components/Alert/Alert'
import { Button } from 'src/components/Button/Button'
import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { IconLoading } from 'src/components/Icons/IconLoading'
import { Link } from 'src/components/Link/Link'
import { Text } from 'src/components/Text/Text'
import { config } from 'src/config'

import styles from './Newsletter.module.scss'

import type { AxiosResponse } from 'axios'
import type { FormEvent } from 'react'

const NEWSLETTER_DEFAULT = 'default'
const NEWSLETTER_ESPRESSO = 'espresso'
const NEWSLETTER_RAFFLE = 'raffle'

export interface NewsletterProps {
  newsletterType: 'default' | 'espresso' | 'raffle'
}

type NewsletterEmarsysIdentifier = 'newsletter' | 'espressoseminar' | 'lifecycle'

interface NewsletterAttributes {
  header?: string
  paragraph?: string
  buttonCTA: string
  emarsysIdentifier: NewsletterEmarsysIdentifier
}

const postRouteBase = `${config.serviceHost}/api/newsletter/sub`

export const Newsletter = ({ newsletterType }: NewsletterProps) => {
  // Newsletter suborganism does not directly contain any logic related to form submission & co.
  const [subscriptionError, setSubscriptionError] = useState(false)
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false)
  const [subscriptionMessage, setSubscriptionMessage] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [isBusy, setIsBusy] = useState(false)
  const router = useRouter()

  const { t } = useTranslation()

  const NEWSLETTERS = {
    [NEWSLETTER_DEFAULT]: {
      header: t('newsletter.default.header'),
      paragraph: t('newsletter.default.paragraph'),
      buttonCTA: t('newsletter.default.buttonCTA'),
      emarsysIdentifier: 'newsletter' as NewsletterEmarsysIdentifier,
    },
    [NEWSLETTER_ESPRESSO]: {
      header: t('newsletter.espresso.header'),
      paragraph: t('newsletter.espresso.paragraph'),
      buttonCTA: t('newsletter.espresso.buttonCTA'),
      emarsysIdentifier: 'espressoseminar' as NewsletterEmarsysIdentifier,
    },
    [NEWSLETTER_RAFFLE]: {
      buttonCTA: t('newsletter.raffle.buttonCTA'),
      emarsysIdentifier: 'newsletter' as NewsletterEmarsysIdentifier,
    },
  }

  const privacyLink = t('newsletter.privacy.link')
  const privacyButtonText = t('newsletter.privacy.buttonText')

  const newsletterSubscribe = async (event: FormEvent) => {
    event.preventDefault()
    setIsBusy(true)

    const newsletter: NewsletterAttributes = NEWSLETTERS[newsletterType]

    if (!emailValue) {
      setIsBusy(false)
      return
    }

    const postData = {
      email: emailValue,
      newsletter: newsletter.emarsysIdentifier,
      url: router?.asPath,
    }

    let message = ''

    try {
      const { data }: AxiosResponse<{ success: boolean }> = await axios.post(postRouteBase, postData)
      message = data.success
        ? t('newsletter.submitSuccessMessage', { email: postData.email })
        : t('newsletter.submitErrorMessage')
      setSubscriptionSuccess(data.success)
      setSubscriptionError(!data.success)
    } catch (error) {
      message = t('newsletter.submitMessage')
      setSubscriptionSuccess(false)
      setSubscriptionError(true)
      // eslint-disable-next-line no-console
      console.error(error, 'submit Newsletter')
    } finally {
      setSubscriptionMessage(message)
      setEmailValue('')
      setIsBusy(false)
    }
  }

  const newsletter: NewsletterAttributes = NEWSLETTERS[newsletterType]
  if (!newsletter) return null

  const { header, paragraph, buttonCTA } = newsletter

  const isDefaultNewsletter = newsletterType === NEWSLETTER_DEFAULT
  const isRaffle = newsletterType === NEWSLETTER_RAFFLE
  const inputFieldLabel = t('newsletter.inputFieldLabel')

  return (
    <section className={clsx(!isRaffle && styles.newsletterWrapper)}>
      <Container className={clsx(styles.bannerContainer, isRaffle && styles.isRaffle)}>
        {!isRaffle && (
          <header className={styles.textAndBadge}>
            {isDefaultNewsletter && (
              <img
                src="/assets/svg/illustrations/subscription.svg"
                alt="subscription"
                width={220}
                height={181}
                loading="lazy"
              />
            )}

            <div>
              <Headline variant="h2">{header}</Headline>
              <Text variant="p" className={styles.paragraph}>
                {paragraph}
              </Text>
            </div>
          </header>
        )}

        <div className={clsx(styles.form, isRaffle && styles.isRaffle)}>
          <form className={styles.form} onSubmit={newsletterSubscribe}>
            <TextInput
              type="email"
              name="email"
              value={emailValue}
              onChange={(event) => setEmailValue(event.currentTarget.value)}
              required
              withAsterisk={false}
              label={inputFieldLabel}
              placeholder="name@email.com"
              error={subscriptionError}
              aria-required="true"
              aria-invalid={subscriptionError ? 'true' : 'false'}
            />
            {subscriptionMessage && (
              <Alert
                // eslint-disable-next-line no-nested-ternary
                variant={subscriptionSuccess ? 'success' : subscriptionError ? 'error' : 'info'}
                className={styles.submitFeedback}
              >
                {subscriptionMessage}
              </Alert>
            )}
            <Button type="submit" name={buttonCTA} className={styles.submitButton} fullWidth>
              {isBusy ? <IconLoading size={16} stroke={1.6} /> : buttonCTA}
            </Button>

            <Link
              href={privacyLink}
              target="_blank"
              className={styles.privacyLink}
              aria-label={`${privacyButtonText} - ${t('common.newTab')}`}
            >
              {privacyButtonText}
            </Link>
          </form>
        </div>
      </Container>
    </section>
  )
}
